import {
  GOVUKInitAll,
  HMRCInitAll,
  ClearCookies,
  ProgressiveDisclosure,
  Webchat,
} from 'dvla-external-frontend';
import formUtils from '../components/js-form-utils';

// ensure the stylesheets are included in the compiled pack
require.context('../stylesheets/', false, /^\.\/[^_].*\.(css|scss|sass)$/i);
// ensure images are included in the compiled pack
require.context('../images', true);
// ensure dvla-external-frontend/dist/media are included in the compiled pack
require.context('dvla-external-frontend/dist/media', true);

// initialise javascript
GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();
ProgressiveDisclosure();
Webchat();

// initialise form utils
window.onload = () => {
  formUtils.registerInputListeners();
};
